import React, { Component } from "react";
import inatjs from "inaturalistjs";
import { Container, Card, Col, Button } from "react-bootstrap";

import "./Answers.css";

const numberOfAnswers = 4;

export default class Answers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answers: [],
      parentID: "",
      isLoading: true,
    };
  }

  async componentDidMount() {      
    const { observation } = this.props;
    const correctAnswer = {
      id: observation.correctAnswer.id,
      name: observation.correctAnswer.name,
      common_name: observation.correctAnswer.preferred_common_name,
      isCorrect: true,
    };
    // The ID of the parent taxon from which answers should be searched
    const parentID = observation.higherTaxon.id;
    // Search for same rank as correct answer, within parent
    const params = {
      rank: observation.correctAnswer.rank,
      per_page: 200,
      taxon_id: parentID,
      is_active: true,
    };
    // TODO: loop through all pages of API results (increases random choice possibilities)
    const taxaResponse = await inatjs.taxa.search(params);
    const results = taxaResponse.results;
    // Generate an array of random numbers in results range
    var arr = [];
    while (arr.length < (numberOfAnswers - 1)) {
      var r = Math.floor(Math.random() * results.length);
      if (arr.indexOf(r) === -1) arr.push(r);
    }
    // Add random answers to the state
    const answers = arr.map((randomNumber) => {
      const taxon = results[randomNumber];
      return ({
        id: taxon.id,
        name: taxon.name,
        common_name: taxon.preferred_common_name,
        isCorrect: false,
      });
    });
    var randomIndex = Math.floor(Math.random() * answers.length);
    answers.splice(randomIndex, 0, correctAnswer);
    this.setState({ answers: answers, isLoading: false });
  }

  handleClick = (answer) => {
    const { answerCallback } = this.props;
    if (!answer) {
      return;
    }
    answerCallback(answer.isCorrect, this.state.answers.filter(a => a.isCorrect)[0]);
  };

  render() {
    const { isLoading } = this.state;
    const answerText = (answer) => {
      const name = `${answer?.name}`;
      if (answer?.common_name) {
        return `${answer?.common_name} (${name})`;
      }
      return `${name}`;
    };
    return (
      <Container fluid>
        <Card>
          {this.state.answers.map((answer) => {
            return (
              <Col
                key={answer.name}
                style={{
                  margin: 1,
                  padding: 8,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="primary"
                  size="sm"
                  disabled={isLoading}
                  onClick={!isLoading ? () => this.handleClick(answer) : null}
                >
                  {isLoading ? "Loading answers …" : answerText(answer)}
                </Button>
              </Col>
            );
          })}
        </Card>
      </Container>
    );
  }
}
