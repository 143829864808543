import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

export default class SuggestionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasSuggested: false,
      suggestion: "",
    };
  }

  handleSubmit = (e) => {
    const { observationID } = this.props;
    const { suggestion } = this.state;
    const formValues = { suggestion: `${observationID}: ${suggestion}` };
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "suggestion", ...formValues }),
    })
      .then()
      .catch((error) => alert(error));
    e.preventDefault();
    this.setState({ hasSuggested: true });
  };

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    const { suggestion, hasSuggested } = this.state;
    if (hasSuggested) {
        return "Thanks for your suggestion!";
    }
    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Group controlId="exampleForm.ControlTextarea1">
          <Form.Label>Is there anything wrong with this photo?</Form.Label>
          <Form.Control
            as="textarea"
            name="suggestion"
            value={suggestion}
            rows={3}
            onChange={this.handleChange}
          />
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form.Group>
      </Form>
    );
  }
}
