import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Link } from 'react-router-dom';

import * as ROUTES from "../constants/routes";

import './Home.css';

export default class Home extends Component {
    render() {
        return (
          <Container style={{ padding: 32 }}>
            <Card>
              <Card.Body>
                <Card.Title>Try BioGuessr Free</Card.Title>
                <Card.Text>
                  This is a completely free game with photos from iNaturalist
                </Card.Text>
                <Link to="/game">
                  <Button size="lg" variant="primary">
                    Play
                  </Button>
                </Link>
              </Card.Body>
            </Card>
            <div style={{ height: 20 }}></div>
            <Card>
              <Card.Body>
                <Card.Title>Sign up for an account</Card.Title>
                <Card.Text>To see your stats and collect badges</Card.Text>
                <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
              </Card.Body>
            </Card>
          </Container>
        );
    }
}
