import React, { Component } from 'react';
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

import { withAuthentication } from "./components/Session";

import NavBar from "./components/NavBar";
import Routes from "./Routes";
import * as ROUTES from "./constants/routes";

import './App.css';

class App extends Component {
  render() {
    return (
      <div className={classNames("App", {
        padtop: ROUTES.GAME !== this.props.location.pathname
      })}>
        <NavBar />
        <Routes />
      </div>
    );
  }
}

export default compose(withRouter, withAuthentication)(App);


