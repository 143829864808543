import React, { Component } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import ReactLoading from "react-loading";

import GameStats from "../components/GameStats";
import Answers from "../components/Answers";
import SuggestionForm from "../components/SuggestionForm";

import observations from "./observations.json";

import "./Game.css";

const numberRounds = 10;

const higherTaxa = observations.map((obs) => obs.higherTaxon);
var uniqueHigher = higherTaxa.filter(
  (v, i, a) => observations.filter((obs) => obs.higherTaxon.id === v.id).length > 10 && a.findIndex((t) => t.id === v.id) === i
);

export default class Home extends Component {
  // This state will be used to reset the game after it is finished
  GAME_INITIAL_STATE = {
    isPlaying: true,
    round: 1,
    score: 0,
    isFinished: false,
    gameObservations: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      ...this.GAME_INITIAL_STATE,
      isLoading: true,
      currentObservationID: "",
      photoURL: "",
      isCorrect: undefined,
      higherTaxonFilter: undefined,
    };
  }

  async componentDidMount() {
    const observation = await this.getRandomObservation();
    this.setObservationState(observation);
  }

  setObservationState(observation) {
    const { gameObservations } = this.state;
    const currentObservationID = observation.id;
    const photos = observation.photos;
    const photoURL = photos[0].url.split("?")[0].replace("square", "large");
    const photoAttribution = photos[0].attribution;
    this.setState({
      photoURL,
      currentObservationID,
      observation,
      photoAttribution,
      isLoading: false,
      gameObservations: gameObservations.concat(observation),
    });
  }

  async getRandomObservation() {
    const { higherTaxonFilter, gameObservations } = this.state;
    let observationsWithFilter = observations;
    if (higherTaxonFilter) {
      observationsWithFilter = observationsWithFilter.filter(
        (obs) => obs.higherTaxon.id === higherTaxonFilter.id
      );
    }
    if (gameObservations.length > 0) {
      const notPlayedObservations = observationsWithFilter.filter((obs) =>
        gameObservations.every((gObs) => gObs.id !== obs.id)
      );
      if (notPlayedObservations.length > 0) {
        observationsWithFilter = notPlayedObservations;
      }
    }
    const randomNumber = Math.floor(
      Math.random() * observationsWithFilter.length
    );
    const observation = observationsWithFilter[randomNumber];
    return observation;
  }

  answerPressed = async (isCorrect, correctAnswer) => {
    const { score, round } = this.state;
    let newScore = score;
    if (isCorrect) {
      newScore += 1;
    }
    const isFinished = round >= numberRounds;
    this.setState({
      isPlaying: false,
      isCorrect,
      score: newScore,
      correctAnswer,
      isFinished,
    });
  };

  newRoundPressed = async () => {
    this.setState({ isLoading: true });
    const observation = await this.getRandomObservation();
    this.setObservationState(observation);
    this.setState({ isPlaying: true, round: this.state.round + 1 });
  };

  newGamePressed = async () => {
    this.setState(this.GAME_INITIAL_STATE);
    this.setState({ isLoading: true });
    const observation = await this.getRandomObservation();
    this.setObservationState(observation);
  };

  renderRound() {
    const { photoURL, photoAttribution, observation, round, score, higherTaxonFilter } =
      this.state;
    if (!observation) return null;
    return (
      <div>
        <img className="center-fit" src={photoURL} alt="Generic placeholder" />
        <div className="bottom-left">
          <Card>
            <Container>
              <Row>
                <Col>{photoAttribution}</Col>
              </Row>
            </Container>
          </Card>
        </div>
        <div className="top-right">
          <GameStats gameName="Test" round={round} score={score} />
        </div>
        <div className="top-left">
          <Dropdown>
            <Dropdown.Toggle id="higher-taxon-filter">
              {higherTaxonFilter ? higherTaxonFilter.name : "Filter by class"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                as="button"
                onClick={() => {
                  this.setState({ higherTaxonFilter: null }, () => {
                    this.answerPressed(false);
                    this.newGamePressed();
                  });
                }}
                >
                Remove filter
              </Dropdown.Item>
              {uniqueHigher.map((uH) => (
                <Dropdown.Item
                  key={uH.id}
                  as="button"
                  onClick={() => {
                    this.setState({ higherTaxonFilter: uH }, () => {
                      this.answerPressed(false);
                      this.newGamePressed();
                    });
                  }}
                >
                  {uH.name +
                    (uH.preferred_common_name
                      ? ` (${uH.preferred_common_name})`
                      : "")}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="bottom-right">
          <Answers
            observation={observation}
            answerCallback={this.answerPressed}
          />
        </div>
      </div>
    );
  }

  renderGameFinished() {
    const { isFinished, score, gameObservations } = this.state;
    if (!isFinished) return null;
    const text = `You got ${score} points in total`;
    const ImageUnit = ({ playedObservation }) => (
      <Col className="fill" style={{ padding: 0 }}>
        <img
          className="large-fit"
          src={playedObservation.photos[0].url
            .split("?")[0]
            .replace("square", "medium")}
          alt="Generic placeholder"
        />
      </Col>
    );
    return (
      <div style={{ marginTop: 56, height: "100vh", width: "100vw" }}>
        <Row style={{ maxHeight: "33vh" }}>
          {gameObservations
            .slice(0, gameObservations.length / 2)
            .map((playedObservation) => (
              <ImageUnit
                playedObservation={playedObservation}
                key={`${playedObservation.id}`}
              />
            ))}
        </Row>
        <Row style={{ maxHeight: "33vh" }}>
          {gameObservations
            .slice(-(gameObservations.length / 2))
            .map((playedObservation) => (
              <ImageUnit playedObservation={playedObservation} />
            ))}
        </Row>
        <Card className="small-fit">
          <Card.Body>
            <Card.Title>Game finished, well done!</Card.Title>
            <Card.Text>{text}</Card.Text>
            <Button
              variant="primary"
              size="lg"
              onClick={() => this.newGamePressed()}
            >
              Play next game
            </Button>
          </Card.Body>
        </Card>
      </div>
    );
  }

  renderIntermediate() {
    const {
      currentObservationID,
      photoURL,
      isPlaying,
      isCorrect,
      correctAnswer,
    } = this.state;
    if (isPlaying) return null;
    const title = `Your guess was ${isCorrect ? "correct" : "wrong"}.`;
    const answerText = (answer) => {
      const name = `${answer?.name}`;
      if (answer?.common_name) {
        return `${answer?.common_name} (${name})`;
      }
      return `${name}`;
    };
    const text = isCorrect
      ? "You earned 1 point."
      : `The correct answer was: ${answerText(correctAnswer)}`;
    return (
      <div style={{ marginTop: 56 }}>
        <img className="small-fit" src={photoURL} alt="Generic placeholder" />
        <Card className="small-fit">
          <Card.Body>
            <Card.Title>{title}</Card.Title>
            <Card.Text>{text}</Card.Text>
            <Button
              variant="primary"
              size="lg"
              onClick={() => this.newRoundPressed()}
            >
              Play next round
            </Button>
          </Card.Body>
          <Card.Footer>
            <SuggestionForm observationID={currentObservationID} />
          </Card.Footer>
        </Card>
      </div>
    );
  }

  render() {
    const { isLoading, isPlaying, isFinished } = this.state;
    if (isLoading) {
      return (
        <div className="center">
          <ReactLoading
            type={"bubbles"}
            color={"#000000"}
            height={"20%"}
            width={"20%"}
          />
        </div>
      );
    }
    if (isFinished) {
      return this.renderGameFinished();
    }
    if (!isPlaying) {
      return this.renderIntermediate();
    }
    return this.renderRound();
  }
}
