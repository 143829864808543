import React from "react";
import { Route, Switch } from "react-router-dom";

import Home from "./containers/Home";
import Game from "./containers/Game";

import SignUpPage from "./components/SignUp";
import SignInPage from "./components/SignIn";
import SignOutButton from "./components/SignOut";
import PasswordForgetPage from "./components/PasswordForget";
import Account from "./components/Account";

import * as ROUTES from "./constants/routes";

const Routes = () => (
  <Switch>
    <Route path={ROUTES.HOME} exact component={Home} />
    <Route path={ROUTES.GAME} exact component={Game} />
    {/* <Route path={ROUTES.PHOTOS} exact component={Photos} /> */}
    <Route path={ROUTES.SIGN_UP} exact component={SignUpPage} />
    <Route path={ROUTES.SIGN_IN} exact component={SignInPage} />
    <Route path={ROUTES.SIGN_OUT} exact component={SignOutButton} />
    <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
    <Route path={ROUTES.ACCOUNT} exact component={Account} />
    <Route component={Home} />
  </Switch>
);

export default Routes;
