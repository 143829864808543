import React, { Component } from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "./GameStats.css";

export default class GameStats extends Component {
  render() {
      const { gameName, round, score} = this.props;
    return (
      <Card>
        <Container>
          <Row>
            <Col>
              GAME
              <br></br>
              {gameName}
            </Col>
            <Col>
              ROUND
              <br></br>
              {round}
            </Col>
            <Col>
              SCORE
              <br></br>
              {score}
            </Col>
          </Row>
        </Container>
      </Card>
    );
  }
}
