import React, { Component } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";

import { withFirebase } from "../Firebase";

import * as ROUTES from "../../constants/routes";
import { AuthUserContext } from "../Session";

class NavBar extends Component {
  render() {
    return (
      <Navbar collapseOnSelect bg="dark" variant="dark" fixed="top" expand="lg">
        <Navbar.Brand className="brand" href="/">
          {" BIOGUESSR"}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto"></Nav>
          <AuthUserContext.Consumer>
            {(authUser) =>
              authUser ? (
                <Nav>
                  <Nav.Link>
                    {" "}
                    <Link to={ROUTES.ACCOUNT}>{authUser.email}</Link>
                  </Nav.Link>
                </Nav>
              ) : (
                <Nav>
                  <Nav.Link>
                    {" "}
                    <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
                  </Nav.Link>
                  <Nav.Link>
                    {" "}
                    <Link to={ROUTES.SIGN_IN}>Sign In</Link>
                  </Nav.Link>
                </Nav>
              )
            }
          </AuthUserContext.Consumer>
        </Navbar.Collapse>
      </Navbar>
    );
  }
};

export default withFirebase(NavBar);
