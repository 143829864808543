import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  updatePassword,
} from "firebase/auth";
import { getDatabase, ref, set } from "firebase/database";

var config = {
  apiKey: "AIzaSyDiKazx6oNeRh8VHtHjZks5lIO3AOF2Ukg",
  authDomain: "bioguessr.firebaseapp.com",
  databaseURL:
    "https://bioguessr-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "bioguessr",
  storageBucket: "bioguessr.appspot.com",
  messagingSenderId: "968615779700",
  appId: "1:968615779700:web:0b63f3fd86fd0f2c466021",
  measurementId: "G-D1YQNK09W3",
};

class Firebase {
  constructor() {
    const app = initializeApp(config);
    this.auth = getAuth(app);
    this.db = getDatabase(app);
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    createUserWithEmailAndPassword(this.auth, email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    signInWithEmailAndPassword(this.auth, email, password);

  doSignOut = () => signOut(this.auth);

  doPasswordReset = (email) => sendPasswordResetEmail(this.auth, email);

  doPasswordUpdate = (password) =>
    updatePassword(this.auth.currentUser, password);

  // *** User API ***

  user = (uid) => ref(this.db, `users/${uid}`);
  setUser = (uid, value) => set(this.user(uid), value);

  users = () => ref(this.db, "users");
}

export default Firebase;
